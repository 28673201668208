import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import ImageGallery from './utilites/product-images'
import MainInfo from './utilites/mainspecs'
import ProductItem from './ProductListingItem'
import Cta from '../sections/Cta'
import Emailer from '../sections/Emailer'

class Interior extends React.Component  {
    constructor(props) {
        super(props)

        const options = props.product.beadMouldings.beadMouldings
        const cur = 0
        
        this.state = { 
            productOverwrides : null,
            option: options[cur],
            title: props.product.title,
            mainImage: {
                url: options[cur].images[0].sourceUrl,
                alt: options[cur].images[0].altText,
            },
            thumbnails: options[cur].images,
            price: null,
            sizes: options[cur].sizes,          
            options: options,
            desc: options[cur].description,
            related: [
                props.product.relatedProducts.product1,
                props.product.relatedProducts.product2,
                props.product.relatedProducts.product3,
                props.product.relatedProducts.product4,
            ],
            showRelated: props.product.relatedProducts.showRelatedProducts
        }

    }

    toggleOptions(id) {
        const option = this.state.options[id.value]
        this.setState({
            option: option,
            mainImage: {
                url: option.images !== null ? option.images[0].sourceUrl : this.state.options[0].images[0].sourceUrl,
                alt: option.images !== null ? option.images[0].sourceUrl : this.state.options[0].images[0].altText,
            },
            sizes: option.sizes,
            thumbnails: option.images !== null ? option.images : this.state.options[0].images,
            desc: option.description,
        })
    }
    
    updateImage = (id) => {
        this.setState({
            mainImage: {
                url: this.state.option.images[id].sourceUrl,
                alt: this.state.option.images[id].altText,
            },
        })
    }

    relatedEmpty() {
        let empty = this.state.related.filter(item => item )
        return empty.length !== 0 ? true : false
    }

    // Check Sizes
    checkSizesExists() {
        let sizes
        if (this.state.sizes !== null) {
            sizes = 
            <div>
                <span className="text-xl font-light mb-4 block border-b border-solid border-l-0 border-t-0 border-r-0  border-gray-100">Sizes:</span>
                <div className="flex flex-wrap mb-6 lg:mb-0">
                    <div className="w-full border-primary flex flex-wrap items-center mb-4">
                        {this.state.sizes.map((size, i) => {
                            
                            let pp, p, c, cross
                            if (size.pp) {
                                pp = 
                                    <img key={uuidv4()} alt="PP" className="w-8 align-middle pr-2 mb-0" src="https://www.hoppings.co.uk/wp-content/uploads/2019/06/premium_plus_icon.png"/>
                            } 
                            if (size.p) {
                                p =
                                    <img key={uuidv4()} alt="P" className="w-8 align-middle pr-2 mb-0" src="https://www.hoppings.co.uk/wp-content/uploads/2019/06/premium_icon.png" />  
                            }
                            if (size.c) {
                                c =
                                    <img key={uuidv4()} alt="C" className="w-8 align-middle pr-2 mb-0" src="https://www.hoppings.co.uk/wp-content/uploads/2019/06/contract_icon.png" /> 
                            }
                            if (size.cross) {
                                cross = 
                                    <span key={uuidv4()} alt="Cross" className="mr-2 text-sm">†</span>
                            }
                            return (
                                <div key={uuidv4()} className="w-1/2 pr-8">
                                    <p className="checkChange mb-2 border-solid font-bold border-gray-100 border-b border-l-0 border-t-0 border-r-0">
                                        <span className="pr-2">{size.size}</span>
                                        {pp}{p}{c}{cross}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        } else {
            sizes = null
        }
        return sizes
    }
    
    render() {
        let sizes = this.checkSizesExists()
        return (
            <div className="container mx-auto">
                <div className="mb-16 md:mb-24">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 md:pr-12 lg:pr-24">
                            <ImageGallery 
                                toggle={this.updateImage}
                                thumbnails={this.state.thumbnails}
                                mainImageUrl={this.state.mainImage.url}
                                mainImageAlt={this.state.mainImage.alt} 
                            />                        
                        </div>
                        <div className="w-full md:w-1/2 md:pl-8">
                            {this.state.title && <h1 className="text-4xl leading-normal">{this.state.title}</h1>}
                            <MainInfo price={this.state.price}/>
                            <span className="block text-xl font-light mb-2">Profile/Option:</span>
                                <select onChange={e=>this.toggleOptions(e.target)} id="selectOptions" className="mb-5 p-2">
                                    {this.state.options && this.state.options.map((e, i) => (
                                        <option className="p-2" key={i} value={i}>{e.profileoption}</option>
                                    ))}
                                </select>
                                {sizes}
                            {this.state.desc && 
                                <div className="product__info">
                                    <div className="border-b border-solid border-l-0 border-t-0 border-r-0 mr-4 md:mr-8 pb-3 mb-5 relative cursor-pointer text-xl md:text-2xl font-bold text-primary">Information</div>
                                    <div dangerouslySetInnerHTML={{__html: this.state.desc}} />
                                </div>
                            }
                        </div>
                    </div>
                </div>  
                {(this.relatedEmpty() && this.state.showRelated) && <div>
                    <h3 className="px-4 text-3xl mb-8 text-center">Related Products</h3>
                    <div className="flex flex-wrap  mb-8 md:mb-16">
                        {this.state.related.map((product, i) =>  (
                            product && <ProductItem key={i} product={product} />
                        ))}
                    </div>
                </div>}
                <Cta />
                <Emailer />
           </div> 
        )
    }
}

export default Interior