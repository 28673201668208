import React from 'react'

class MainInfo extends React.Component {
    render() {
        return <div>
            <div className="flex flex-wrap flex-col justify-between text-2xl md:text-3xl mb-8">
                {this.props.price && <div className="font-light">
                    <b>Price:</b> £{this.props.price}
                </div>
                }
                {this.props.dimensions && <div className="font-medium">
                    {this.props.dimensions}
                </div>
                }
            </div>
        </div>
    }
}

export default MainInfo