import React from 'react'

import './Thumbnail.style.scss'

class Thumbnail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: parseInt(this.props.last, 10) === this.props.index ? true : false
        }
    }
    toggle(id) {
        this.props.lastClicked(id)
        this.props.toggle(id)
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }
    // Toggle active thumbnails classes 
    classLogic() {
        return `thumbnail w-16 mb-2 border-solid 
            border${((this.state.active && parseInt(this.props.last, 10) === this.props.index) || parseInt(this.props.last, 10) === this.props.index) ? '-1' : ''} 
            border-gray-300 hover:border-primary rounded-sm p-1 mx-2 pointer-cursor`
    }
    render() {
        return (
            <img onKeyDown={e=>this.toggle(e.target.id)} onClick={e=>this.toggle(e.target.id)} id={this.props.index} className={this.classLogic()} src={this.props.src} alt={this.props.alt} />
        )
    }
}

export default Thumbnail
