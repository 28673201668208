import React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'
import Img from "gatsby-image"

const Cta = () => (
  <StaticQuery query={query} render={props => (
      props.contentfulCta.show && (
        <div className="flex flex-wrap mb-16 md:mb-24">
          <div className="w-full">
            <Link to={props.contentfulCta.link}>
              <Img imgStyle={{objectFit: "cover"}}  className="max-w-full w-full max-h-xs" alt={props.contentfulCta.image.description} fluid={props.contentfulCta.image.fluid} />
            </Link>
          </div>
        </div>
      )
    )}
  />
)

export default Cta

const query = graphql`
{
  contentfulCta(id: {eq: "9957803f-a75b-56ae-9d3a-91e7584bb139"}) {
    link
    show
    image {
      description
      fluid(maxWidth: 1920, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }
}
`
