import React from 'react'

import Thumbnail from '../components/Thumbnail'

class ImageGallery extends React.Component {
    constructor () {
        super()
        this.state = { lastClicked: 0 }
    }
    updateLastClicked = (id) => {
        this.setState({
            lastClicked: id
        })
    }
    render() {
        return (
            <div>
                <img className="w-full border border-solid" alt={this.props.mainImageAlt} src={this.props.mainImageUrl} />
                <div className="-ml-2 flex flex-wrap">
                    {this.props.thumbnails.map((thumbnail, index) => (
                        <Thumbnail key={index} index={index} last={this.state.lastClicked} lastClicked={this.updateLastClicked} toggle={this.props.toggle} src={thumbnail.sourceUrl} alt={thumbnail.altText} />
                    ))}
                </div>
            </div>
        )
    }
}

export default ImageGallery