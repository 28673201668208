import React from 'react'

class Breadcrumbs extends React.Component {
    /*breadcrumb() {
        let url = window.location.href.split('/'); 
        let homeURL = `${url[0]}//${url[1]}${url[2]}/`
        let breadcrumb = `<li class="mb-0"><a class="text-black font-bold mb-0 no-underline" href="${homeURL}">Home</a> <span class="divider mx-2">/</span>`;
        //Use a for loop
        for(let i = 3; i < url.length; i++ ) {
            if( url[i]!== '' ) {
                homeURL += `${url[i]}/`          
                console.log(homeURL)
                if (url.length - 2 === i) {
                    breadcrumb += `<li class="mb-0">${url[i]}</li>`
                } else {
                    breadcrumb += `<li class="mb-0"><a class="font-bold mb-0 no-underline" href="${homeURL}">${url[i]}</a> <span class="divider mx-2">/</span></li> `
                }
            }
        } 
    
        return breadcrumb
    }*/
    render() {
         return (
            <div className="mb-16 md:mb-24 bg-gray-100 text-black py-4"> 
                <div className="container mx-auto">
                    {/*<ul className="mb-0 list-none flex flex-wrap items-center" dangerouslySetInnerHTML={{__html: this.breadcrumb() }} />*/}
                </div>
            </div>
        )
    }
}

export default Breadcrumbs