import React from 'react'
import {Link} from 'gatsby'
import Img from "gatsby-image"

const ProductItem = ( {product} ) => { 
  
  product = Array.isArray(product) ? product[0] : product

  return (
    <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-8">
      <Link to={`${product.fullURl ? `${product.slug}` : `/products/${product.slug}/`}`}>
        <div className="product-item rounded h-full text-center relative overflow-hidden">
          {product.featuredImage.node.localFile.childImageSharp.fluid && <Img imgStyle={{objectFit: "cover"}}  className="max-w-full w-full max-h-xs product-item__thumbnail mx-auto block" alt={product.featuredImage.node.altText} fluid={product.featuredImage.node.localFile.childImageSharp.fluid} /> }
          <div className="product-item__overlay absolute top-0 left-0 w-full h-full"></div>
          {product.title && 
            <div className="product-item__text absolute w-full p-4 bottom-0 text-white">
              <h2 className="leading-tight mb-0 mb-2 font-normal text-lg" dangerouslySetInnerHTML={{__html: product.title}}/>
              <div className="text-sm uppercase no-underline font-medium text-white">
                View product
              </div>
            </div>
          }
        </div>
      </Link>
    </div>
  )
}

export default ProductItem
